.visible {
  visibility: visible;
}

.non-vivsible {
  visibility: hidden;
}

.message-me {
  position: absolute;
  width: 100%;
  height: 100%;
}

.message-me span {
  color: #ffffff;
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
}

.message-me input {
  color: black;
  font-family: "Open Sans", HanziPenSC, myHanziPenSC, sans-serif;
}

.message-me-email span {
  color: #4a4a4a;
}

.message-me-ques span {
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  line-height: 0.5em;
}

.message-me-select span {
  color: #4a4a4a;
  font-family: "Open Sans", HanziPenSC, myHanziPenSC, sans-serif;
}

.message-me-ques {
  background-color: #52acff;
  background-image: linear-gradient(145deg, #52acff 10%, #ffe32c 100%);
  /*background-color: #0077ff;*/
  position: relative;
  width: 100%;
  height: 40%;
  padding: 40px;
  font-size: 42px;
  border-radius: 20px 20px 0px 0px;
}

.message-me-ans {
  background-color: #f8f8f8;
  position: relative;
  width: 100%;
  height: 60%;
  padding: 40px;
  font-size: 14px;
  border-radius: 0px 0px 20px 20px;
}

.message-me-ans input {
  border: 0 solid red;
  border-bottom: 1px solid #000;
  background-color: #f8f8f8;
  line-height: 18px;
  font-size: 18px;
  width: 50%;
}

.message-me-ans input:focus {
  outline: none;
  width: 50%;
}

.message-me-ans a {
  color: #000000;
  margin-left: 10px;
  visibility: hidden;
  display: inline-block;
}

.message-me-select span {
  margin-top: 15px;
  display: block;
}

.message-me-select span:hover {
  cursor: pointer;
}

.message-me-email a {
  visibility: hidden;
  display: inline-block;
}

.message-me-email input {
  margin-left: 6px;
}

.message-me-final {
  display: none;
}

@media only screen and (max-width: 480px) {
  .message-me-ques {
    padding: 25px;
    font-size: 32px;
  }

  .message-me-ans {
    padding: 25px;
    height: 50%;
  }

  .message-me-ans a {
    font-size: 24px;
  }
  .message-me-ans input {
    width: 90%;
  }

  .message-me-ans input:focus {
    width: 90%;
  }

  .visible {
    font-size: 20px;
  }
}

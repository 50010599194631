@import url(https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

:root {
  /*--body-margin: 20px;*/
  --sidebar-width: 250px;

  /*--item-width: calc((100vw - var(--sidebar-width))/2 - 12vw);*/
  /*--remainder-width: calc((100vw - var(--sidebar-width) - 2*var(--item-width) - 2*var(--body-margin))/3);*/

  --remainder-width: 38px;
  --item-width: calc(
    (
        100vw - var(--sidebar-width) - 2 * var(--body-margin) - 3 *
          var(--remainder-width)
      ) / 2
  );
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

	:root {
		--button-width: 200px;
		/*--button-remainder-width: calc((100vw - var(--sidebar-width) - 2*var(--button-width))/3);*/
		--button-remainder-width: calc((100% - 2*var(--button-width))/3);
		--button-bottom-remainder-width: 20%;
		--next-bottom-remainder-width: 10%;
		--button-font-size: 2em;
	}

	*{
		margin: 0;
		padding: 0;
		list-style:none;
		text-decoration: none;
		font-family: Arial,'微軟正黑體';
		/*font-family: 'cwTeXKai', serif;*/
		box-sizing: border-box;
		/*color: #e0e0e0;*/
		color: #DCDADA;
	}

	html{
		/*background-color: #292929;*/
	}

	body{
		width: 100%;
		height: 100%;
		position: relative;
		/*left:100vw;*/
		font-size: 100%;
	}



/* ===================(左側導覽)=================== */

	.nav{
		width: 10px;
		height: 25.63vw;
		/*background-color: #e0e0e0;*/
		position: fixed;
		top:0;
		bottom:0;
		margin: auto 0;
		/*position: relative;*/
		z-index:3;
	}

	.nav a li{
		width: 100%;
		height: 4.66vw;
		background-color: #ccc;
		margin-bottom: 5px;
	}
 
 
	.nav a li:hover{
		background-color: #9a1626;
	}

	.nav li{
		width: 100%;
		height: 4.66vw;
		background-color: #ccc;
		margin-bottom: 5px;
	}

 
/* =================== sect =================== */

	/* Search for <div> with class name is sectXX */
	div[class^='sect']{ 
		display: none;
		position: absolute;
		/*width: 100%;*/
		/*height: 100%;*/
		/*width: 100wv;*/
		/*height: 100hv;*/
		/*filter: brightness(70%);*/
		/*backdrop-filter: brightness(70%);*/
		/*-webkit-backdrop-filter: blur(10px);*/
		width: 100%;
		height: 100%;
	}

	div .sect1 {
		display: block;	
	}

	.sect0{
		background: url(/static/media/index.3e7fefcb.jpg) no-repeat;
		background-size: cover;
		transition: background 3s; 
	}

	.sect1{
		background: url(/static/media/test_1.b92de1e0.jpg) no-repeat;
		background-size: cover;
		transition: background 3s; 
	}

	.sect2{
		background: url(/static/media/test_2.9079f15a.jpg) no-repeat;
		background-size: cover;
		transition: background 3s; 
	}

	.sect3{
		background: url(/static/media/test_3.49f32757.jpg) no-repeat;
		background-size: cover;
		transition: background 3s; 
	}

	.sect4{
		background: url(/static/media/test_4.87711757.jpg) no-repeat;
		background-size: cover;
		transition: background 3s; 
	}

	.sect5{
		background: url(/static/media/test_5.10026a39.jpg) no-repeat;
		background-size: cover;
		transition: background 3s; 
	}

	.trunk{
		/*position:absolute;*/
		/*height: 100vh;*/
		/*width: 100vw;*/
		width: 100%;
		height: 100%;
		margin: 0 auto;
		z-index: 1;
		top: 0;
		left:0;
		/*margin-left:auto;*/
		/*margin-right:auto;*/
		margin:auto;
	}


/* ===================(主幹:上半)=================== */
	.note{
		width: 100%;
		/*height: 44.54vh;*/
		height: 40vh;
		/*background-color: #698f42;*/
		text-align: center;
		position: relative;
	}

	.note div{
		width: 100%;
		height: 30%;
		/*background-color: #ccc;*/
		position: absolute;
		/* line-height: 14vh; 44.54vh / 3 */
		line-height: 10vh;
	}

	.order{
		font-size: 1.125em;
		/*position: relative;*/
	}

	.comment{
		/*font-size: 3em;*/
		font-size: 6vmin;
		top:0;
		bottom:0;
		margin:auto 0;
		/*position: relative;*/
		/*left: 0;*/
		/*top: 0;*/
	}

	.comment p{
		display: inline-block;
		padding: 0 15% 0;
		line-height: 1em;
		text-align: left;
	}

	.comment p span{
		display: inline-block;
		width: 1.2em;
		height: 0.9em;
		background-color: #fff;
	}

	.comment img{
		/*width: 4.98vw;*/
		/*height: 4.98vw;*/
		/*line-height: 14vh;*/
		vertical-align: text-bottom;
		/*opacity: 0;*/
		display: none;
		width: 9vmin;
		margin-left: 2%;
		margin-right: 2%;
	}

	.sect0 .comment p{
		padding: 0;
	}

	.nopadding p{
		padding: 0;
	}

	.answer{
		font-size: 5.8vmin;
		bottom:0;
		display: none;
		margin: auto 0;
		/*position: relative;*/
		/*left: 0;*/
		/*top: 0;*/
	}
	
	.answer p{
		display: inline-block;
		text-align: left;
		line-height: 1em;
		padding: 0 15% 0;
	}

	.intro{
		display: inline-block;	
		padding: 5%;
		/*width: 33.33333%*/
	}

	.intro img{
	
		width: 50px;
	}

	.intro .desc{
	
		font-size: 2em;
	}

	/*.intro1 img {*/
		/*background: url('./icon/index_icon1.svg') no-repeat;	*/
		/*width: 120px;*/
		/*height: 120px;*/
	/*}*/

	.clicks{
		width: 100%;
		height: 55.45vh;
		/*background-color: #bd9628;*/
		text-align: center;
		/*padding: 5% 25%;*/
		padding: 5% 15%;
	}

	.sect0 .clicks{
		padding-top: 0;	
	}

	.butt{
		border:1px solid #6f6a6a;
		width: 200px;
		width: var(--button-width); 
		height: 200px; 
		height: var(--button-width); 
		/*width: 110px;*/
		/*height: 110px;*/
		/*font-size: 30px;*/
		font-size: 18px;
		border-radius: 99em;
		padding: 4% 2% 4%;
		/*margin-left: 4vw;*/
		/*margin-right: 4vw;*/
		/*margin-top: 4vw;*/
		/*margin-bottom: 4vw;*/
		display: inline-block;
		/* width needs to substract the padding of .butt which is around 20px */ 
		/*line-height: 90px; */
		text-align: center;
		vertical-align: middle;

		transition: opacity 1s;
		position: absolute;
		bottom: 20%;
		bottom: var(--button-bottom-remainder-width);
	}

	.butt span{
		color: #DCDADA;
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	} 


	.butt_left{
		cursor: pointer;
		left: calc((100% - 2*200px)/3);
		left: var(--button-remainder-width);
	}

	.butt_right{
		cursor: pointer;
		right: calc((100% - 2*200px)/3);
		right: var(--button-remainder-width);
	}


	.next{
		width: 100%;
		clear: both;
		padding-top: 2vw;
		margin-top: -4vw;

		transition: display 2s, -webkit-transform 2s;

		transition: transform 2s, display 2s;

		transition: transform 2s, display 2s, -webkit-transform 2s;
		cursor: pointer;

		position: absolute;
		left: 0;
		bottom: 10%;
		bottom: var(--next-bottom-remainder-width);

		display: none;
	}

	.next a{
		display: inline-block;
		font-size: 1em;
		padding: 1vw 2vw;
		border:2px solid #e0e0e0;
		border-radius: 4px;
	}

	.next span{
		display: inline-block;
		font-size: 1.5em;
		padding: 1vw 2vw;
		border:1px solid #6f6a6a;
		border-radius: 4px;
		width: 100%;
	}

	.start{
		margin-top: 1%;
		margin-bottom: 5%;
	}


	.heighlight {
	
		color: #B3E2BE;
	}

	.disable {
		pointer-events: none;
		color: #777777;
		border:2px solid #777777;
	}

	.result {
		position: absolute;
		/*width: 100vw;*/
		width: 100%;
		/*height: 100vh;*/
		height: 100%;
		display: none;
		background-size: cover;
	}

	.result h2 {
		margin: 5%;
		text-align: center;
	}

	.result h1 {
		text-align: center;
		margin: 5%;
	}

	.end h1 {
		position: relative;
		/*top: 10vh;*/
		left: 0;
	}

	.end h2 {
		position: relative;
		/*top: 10vh;*/
		left: 0;
	}


	@media only screen and (min-width: 480px)
	{
		body{
			font-size: 110%;
		}

		.quiz-container{
			margin-top: calc(var(--header-width) + var(--remainder-width));
		
		}
	}

	@media only screen and (min-width: 768px)
	{
		body{
			font-size: 120%;
		}

		.quiz-container{
			margin-top: calc(var(--header-width) + var(--remainder-width));
		
		}

		.butt{
			width: 15vw;
			height: 15vw;
			width: 200px;
			width: var(--button-width);
			height: 200px;
			height: var(--button-width);
			/* width needs to substract the padding of .butt which is around 4% or 4vw */ 
			/*line-height: 11vw;*/ 
			/*font-size: 3em;*/
			/*font-size: 2vw;*/
			font-size: 2em;
			font-size: var(--button-font-size);
		}

		.comment{
			/*font-size: 4em;*/
			font-size: 6vmin;
		}

		.next span{
			font-size: 1em;
			width: auto;
		}

	}

.Sidebar {
  position: fixed;
  left: 0;
  top: calc(var(--body-margin));
  width: 190px;
  margin-left: 55px;
  height: 80%;
  /*overflow: scroll; */
}

.Author {
  margin-bottom: 50px;
}

.Author p {
  font-size: 24px;
  text-align: left;
  font-family: AlTarikh;
  padding-left: 15px;
  color: #000000;
}

.Author img {
  width: 125px;
}

.list ul {
  list-style-type: none;
  text-align: left;
  padding-left: 15px;
  padding-top: 15px;
}

.list ul li {
  padding-bottom: 15px;
}

.list ul a {
  font-family: "ABeeZee", sans-serif;
  color: #000000;
}

.unfocused a,
.focused a {
  text-decoration: none;
}

.list div,
.sublist div {
  display: inline-block;
  :w ;
}
/********************************
sidebar sub-list
it becomes bold when hovering
*********************************/
.sublist {
  font-size: 12px;
  color: #ffffff;
}

.sublist .focused a {
  font-weight: bold;

  text-decoration: none;
  font-size: 14px;
}

.sublist .unfocused a {
  font-weight: normal;
  text-decoration: none;
  font-size: 14px;
}

/********************************
sidebar list
css sliding underline -- from left to right 
*********************************/

.list > div > a {
  display: inline-block;
}

.list > div > a:after {
  content: "";
  display: block;
  height: 1px;
  width: 0;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}

.list > .focused a:after {
  width: 100%;
  /*background: black;*/
  background: #ffffff;
}

@media only screen and (min-width: 768px) {
  .sublist > div > a:hover {
    font-weight: bold;
  }

  .list > div > a:hover:after {
    width: 100%;
    background: black;
  }
  .sublist .focused a {
    font-size: 12px;
  }

  .sublist .unfocused a {
    font-size: 12px;
  }
}

:root {
  --body-margin: 55px;
  --sidebar-width: 245px;
  /*--item-width: calc((100vw - var(--sidebar-width))/2 - 12vw);*/
  /*--remainder-width: calc((100vw - var(--sidebar-width) - 2*var(--item-width) - 2*var(--body-margin))/3);*/

  --remainder-width: 60px;
  --item-width: calc(
    (
        100vw - var(--sidebar-width) - 2 * var(--body-margin) - 3 *
          var(--remainder-width)
      ) / 2
  );

  --main-height: calc(100vh - 2 * var(--body-margin));
  --main-width: calc(100vw - 2 * var(--body-margin));

  --header-width: 55px;
}

body {
  /*margin: var(--body-margin);*/
  /*margin-top: calc(2*var(--body-margin));*/
  padding: 55px;
  padding: var(--body-margin);
  font-size: 14px;
  line-height: 1.333333;
  letter-spacing: 0.05em;
}

* {
  box-sizing: border-box;
  visibility: visible;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.1;
}

span .thick {
  font-weight: bold;
}

.myPortfolio {
  position: relative;
}

div {
  display: block;
}

.ham {
  display: inline-block;
  width: 23px;
  height: 2px;
  position: relative;
  top: 2px;
  vertical-align: bottom;
  background: #b1004a;
  white-space: nowrap;
  transition: all ease 0.3s;
}

.menu-bar {
  min-width: 44px;
  height: 44px;
  text-align: center;
  display: inline-block;
  z-index: 101;
  transition: all ease 0.3s;
  -webkit-animation: blinkBackground 4s infinite;
  animation: blinkBackground 4s infinite;
  -webkit-box-shadow: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.menu-wrapper {
  display: table;
  table-layout: auto;
  width: 100%;
}

.header {
  display: none;
  padding: 13px;
}

.logo {
  display: table-cell;
  vertical-align: middle;
  width: 1%;
  font-size: 15px;
  line-height: 1;
  position: fixed;
  /*top:3.5%;*/
  left: 5%;
}

.logo img {
  width: 70px;
}

.menu {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  position: relative;
  line-height: 1.5;
  position: fixed;
  right: 5%;
  top: 1%;
  transition: all ease 0.3s;
}

.ham {
  background-color: #000;
}

.ham:after,
.ham:before {
  position: absolute;
  content: "";
  display: inline-block;
  width: 23px;
  height: 2px;
  transition: all ease 0.3s;
  outline: 1px solid transparent;
  left: 0;
  background: #00b19e;
  background-color: #000;
}

.ham:after {
  top: 8px;
}

.ham:before {
  top: -8px;
}

a {
  background-color: transparent;
}

.exit {
  position: absolute;
  right: 5%;
  top: 1%;
  z-index: 100;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.exit span {
  color: #ffffff;
  font-size: 22px;
}
.exit .ham {
  background-color: transparent !important;
}

.exit .ham:before {
  -webkit-transform: translateY(8px) rotateZ(-45deg);
          transform: translateY(8px) rotateZ(-45deg);
  background-color: #fff;
}

.exit .ham:after {
  -webkit-transform: translateY(-8px) rotateZ(45deg);
          transform: translateY(-8px) rotateZ(45deg);
  background-color: #fff;
}

.main-portfolio {
  position: relative;
  width: calc(100% - 245px);
  width: calc(100% - var(--sidebar-width));
  margin-left: 245px;
  margin-left: var(--sidebar-width);
  height: calc(100vh - 2 * 55px);
  height: var(--main-height);
}

.main-portfolio-fullscreen {
  position: relative;
  width: calc(100vw - 2 * 55px);
  width: var(--main-width);
  height: calc(100vh - 2 * 55px);
  height: var(--main-height);
}

.headline {
  margin-top: -10px;
}

.sub-headline {
  margin-bottom: 35px;
}

.headline_2 {
  font-size: 30px;
  font-weight: 600;
  font-family: "ABeeZee", HanziPenSC-W3, myHanziPenSC, sans-serif;
  color: #0077ff;
  margin-top: 30px;
}

.headline_3 {
  font-size: 18px;
  font-family: "ABeeZee", HanziPenSC-W3, myHanziPenSC, sans-serif;
  margin-bottom: 5px;
  color: black;
  font-weight: 600;
}

.headline_4 {
  font-size: 14px;
  font-family: "Open Sans", HanziPenSC-W3, myHanziPenSC, sans-serif;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.pic {
  margin-top: 20px;
  margin-bottom: 35px;
}

.video iframe {
  height: 540px;
  width: 90%;
}

.preface {
  margin-top: 50px;
  margin-bottom: 40px;
  margin-left: 5%;
  margin-right: 18%;
  font-weight: 500;
}

.role,
.time,
.client,
.built {
  margin-left: 5%;
  margin-right: 10%;
  margin-bottom: 10px;
}

.award {
  margin-top: 40px;
  margin-left: 5%;
  margin-right: 10%;
  margin-bottom: -30px;
}

.nda {
  margin-top: 120px;
  margin-left: 5%;
  margin-right: 18%;
}

.role span,
.time span,
.client span,
.built {
  font-family: "Open Sans", HanziPenSC-W3, myHanziPenSC, sans-serif;
  color: #4a4a4a;
  font-size: 14px;
}

.award a {
  margin-top: 5px;
  font-size: 14px;
  font-family: "ABeeZee", HanziPenSC-W3, myHanziPenSC, sans-serif;
  color: #0077ff;
  font-weight: bold;
}

a:hover {
  color: #ffcb45;
}

.nda span {
  font-size: 18px;
  font-family: "Open Sans", HanziPenSC-W3, myHanziPenSC, sans-serif;
  color: lightgray;
  font-style: italic;
}

.about {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 0%;
}

.portfolio-description .title {
  margin-top: 120px;
  margin-bottom: 20px;
}

.portfolio-description {
  margin-top: 40px;
  margin-bottom: 0px;
}

.subtitle {
  margin-top: 30px;
  margin-bottom: -10px;
}

.desc {
  margin-top: 25px;
  /*margin-bottom: 25px;*/
  margin-right: 8%;
}

.external-link a {
  font-family: "ABeeZee", sans-serif, AndaleMono, myAndaleMono;
}
.external-link-text {
  /* background: linear-gradient(
    90deg,
    rgba(123, 212, 145, 1) 0%,
    rgba(232, 124, 82, 1) 100%
  );
  color: transparent;
  background-clip: text; */
  color: #0077ff;
}

.external-link {
  margin-top: 15px;
  margin-bottom: 15px;
  -webkit-text-stroke-width: 1px;
  font-size: 16px;
}

.bottom_margin {
  padding-bottom: 40px;
}

.subdesc {
  margin-top: 10px;
  /*margin-bottom: 25px;*/
  margin-right: 15%;
}

.portfolio-description .headline span {
  font-family: "ABeeZee", sans-serif;
  font-size: 36px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #000000;
  /*-webkit-text-stroke: 0.8px #4a4a4a;*/
  font-weight: 600;
  line-height: 1.25;
}

.portfolio-description .headline div {
  font-family: "ABeeZee", sans-serif;
  font-size: 36px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #000000;
  /*-webkit-text-stroke: 0.8px #4a4a4a;*/
  font-weight: 600;
  line-height: 1.5;
}

.portfolio-description .headline .headline_2 {
  margin-top: 0px;
  line-height: 1.4;
}

.portfolio-description .headline .headline_3 {
  font-size: 18px;
  color: black;
  letter-spacing: 0.05em;
  line-height: 1.5;
  margin-top: 10px;
}

.portfolio-description .sub-headline span {
  font-family: "ABeeZee", sans-serif;
  font-size: 18px;
  text-align: left;
  color: #4a4a4a;
}

.portfolio-description .pic img {
  width: 90%;
}
.portfolio-description .about_pic img {
  width: 80%;
  margin-bottom: 10px;
}
.portfolio-description {
  font-family: "ABeeZee", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.05em;
  line-height: 30px;
  text-align: justify;
  text-align: left;
  color: black;
}
.preface span {
  font-family: "ABeeZee", sans-serif;
  font-size: 22px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-align: justify;
  text-align: left;
  color: #4a4a44;
}

.about span {
  font-family: "Open Sans", HanziPenSC-W3, myHanziPenSC, sans-serif;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.08em;
  text-align: justify;
  text-align: left;
  color: #4a4a4a;
}

@font-face {
  font-family: myAndaleMono;
  src: url(/static/media/AndaleMono.41906d96.ttf);
}

@font-face {
  font-family: myHanziPenSC;
  src: url(/static/media/HanziPenSC.c8dc4cbf.ttf);
}

.portfolio-description .title span {
  /*font-family: HanziPenSC-W3;*/
  font-family: "Open Sans", HanziPenSC-W3, myHanziPenSC, sans-serif;
  font-size: 22px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #000000;
  font-weight: 600;
}

.portfolio-description .desc span {
  font-family: "Open Sans", AndaleMono, myAndaleMono, sans-serif;
  font-size: 14px;
  line-height: 1.79;
  letter-spacing: 0.08em;
  line-height: 30px;
  text-align: justify;
  text-align: left;
  color: #4a4a4a;
}

.portfolio-description {
  /*font-family: AndaleMono;*/
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  font-size: 12px;
  line-height: 1.79;
  letter-spacing: 0.08em;
  text-align: justify;
  text-align: left;
  color: #000000;
}

.subtitle span {
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  font-size: 16px;
  font-style: italic;
  line-height: 1.79;
  letter-spacing: 0.08em;
  text-align: justify;
  text-align: left;
  color: #000000;
}

.portfolio-description .subdesc span {
  /*font-family: AndaleMono;*/
  font-family: "Open Sans", AndaleMono, myAndaleMono, sans-serif;
  font-size: 12px;
  line-height: 1.79;
  letter-spacing: 0.08em;
  line-height: 30px;
  text-align: justify;
  text-align: left;
  color: #4a4a4a;
}

.previous-next {
  margin-top: 100px;
}

.previous-next span {
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  color: #000;
  font-size: 20px;
}

.previous-next div a {
  display: inline-block;
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  font-size: 18px;
  color: #000;
  margin-bottom: 30px;
}

.previous-next .previous-p {
  float: left;
}

.previous-next .next-p {
  float: right;
}

.wrapper {
  position: relative;
}

.container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /*justify-content: space-around;*/
}

.portfolio-item {
  /*position: absolute;
  width: var(--item-width);
  height: var(--item-width);*/
  width: 200px;
  height: 200px;
  margin: 25px;
  transition: all 1s ease-in-out;
  border-radius: 15px;
}

.about_content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 8%;
}

.about_content > div {
  width: 50%;
  margin-bottom: 20px;
}

/*.post0 {
  left: var(--remainder-width);
  top: 0;
}

.post1 {
  left: var(--remainder-width);
  top: 0;
}

.post2 {
  right: var(--remainder-width);
  top: 0;
}

.post3 {
  left: var(--remainder-width);
  top: calc(var(--item-width) + var(--remainder-width));
  top: calc(
    (
        100vw - var(--sidebar-width) - 2 * var(--body-margin) - 3 *
          var(--remainder-width)
      ) / 2 + var(--remainder-width)
  );
}

.post4 {
  right: var(--remainder-width);
  top: calc(var(--item-width) + var(--remainder-width));
  top: calc(
    (
        100vw - var(--sidebar-width) - 2 * var(--body-margin) - 3 *
          var(--remainder-width)
      ) / 2 + var(--remainder-width)
  );
}

.post5 {
  left: var(--remainder-width);
  top: calc(2 * var(--item-width) + 2 * var(--remainder-width));
  top: calc(
    (
        100vw - var(--sidebar-width) - 2 * var(--body-margin) - 3 *
          var(--remainder-width)
      ) + 2 * var(--remainder-width)
  );
}

.post6 {
  right: var(--remainder-width);
  top: calc(2 * var(--item-width) + 2 * var(--remainder-width));
  top: calc(
    (
        100vw - var(--sidebar-width) - 2 * var(--body-margin) - 3 *
          var(--remainder-width)
      ) + 2 * var(--remainder-width)
  );
}
*/

.item1 {
  background: url(/static/media/mainpage_3_0.68d2317b.png) no-repeat;
  background-size: 100%;
}

.item2 {
  background: url(/static/media/mainpage_2.8dd0e119.jpg) no-repeat;
  background-size: 100%;
}

.item3 {
  background: url(/static/media/mainpage_1_0.90029240.png) no-repeat;
  background-size: 100%;
}

.item4 {
  background: url(/static/media/mainpage_4_0.eb48758c.png) no-repeat;
  background-size: 100%;
}

.item5 {
  background: url(/static/media/mainpage_5.390100ae.jpg) no-repeat;
  background-size: 100%;
}

.item6 {
  background: url(/static/media/mainpage_6.521e1c75.png) no-repeat;
  background-size: 100%;
}

.item7 {
  background: url(/static/media/mainpage_7_0.faabfa37.png) no-repeat;
  background-size: 100%;
}

.item8 {
  background: url(/static/media/mainpage_8_1.1ddb2202.png) no-repeat;
  background-size: 100%;
}

.item9 {
  background: url(/static/media/mainpage_9_0.88a72f9b.png) no-repeat;
  background-size: 100%;
}

.item10 {
  background: url(/static/media/mainpage_10.01abda83.png) no-repeat;
  background-size: 100%;
}

.item11 {
  background: url(/static/media/mainpage_11.25319f85.png) no-repeat;
  background-size: 100%;
}

.item12 {
  background: url(/static/media/mainpage_12.0a5d3f85.png) no-repeat;
  background-size: 100%;
}
/*.portfolio-item:hover {*/
/*opacity: 0.98;*/
/*background-color: #eb6460;*/
/*}*/

@media (pointer: fine) {
  .hover-state {
    width: 100%;
    height: 100%;
    padding: 10%;
    position: relative;
    border-radius: 15px;
    box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
    transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  }
  .hover-state:hover {
    box-shadow: 2px 4px 12px rgb(0 0 0 / 35%);
  }
}

.info {
  position: absolute;
  left: 10%;
  right: 10%;
  top: 0;
}

.title h2 {
  position: relative;
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  font-size: 18px;
  letter-spacing: 0.08em;
  text-align: left;
  color: black;
  margin-bottom: 18px;
  /*-webkit-transform: translate(0,100px);
	transform: translate(0,15px);
	-webkit-transition: all .15s 75ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all .15s 75ms cubic-bezier(0.25, 0.46, 0.45, 0.94);*/
  letter-spacing: 0.05em;
  visibility: hidden;
  line-height: 1.5em;
}

.title_below h2 {
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  font-size: 20px;
  letter-spacing: 0.05em;
  text-align: left;
  color: black;
  opacity: 0.75;
  margin-left: 5%;
  margin-bottom: 15px;
  margin-right: 5%;
  line-height: 1.5em;
}
.title_below h2:hover {
  opacity: 1;
}

.subtitle h3 {
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  text-align: left;
  color: #ffffff;
  /*-webkit-transform: translate(0,100px);
	transform: translate(0,15px);
	-webkit-transition: all .15s 75ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all .15s 75ms cubic-bezier(0.25, 0.46, 0.45, 0.94);*/
  letter-spacing: 0.05em;
  visibility: hidden;
  font-weight: 600;
  margin-top: -15px;
}

.subtitle_below h3 {
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  letter-spacing: 0.05em;
  font-size: 12px;
  color: #9b9b9b;
  text-align: left;
  margin-left: 5%;
  margin-right: 5%;
  line-height: 1.5em;
  margin-bottom: 50px;
}

.hover-state:hover .info {
  position: absolute;
  left: 10%;
  top: 60%;
}

.hover-state:hover .info .title h2 {
  color: #ffffff;
}

.hover-state:hover .info .subtitle h3 {
  color: #ffe5e5;
  display: block;
}
.hover-state:hover .title h2 {
  -webkit-transform: translate(0, -100px);
  transform: translate(0, -15px);
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  letter-spacing: 0.05em;
  /*visibility: visible;*/
}
.hover-state:hover .subtitle h3 {
  -webkit-transform: translate(0, -100px);
  transform: translate(0, -15px);
  transition: all 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  letter-spacing: 0.05em;
  /*visibility: visible;*/
}

.portfolio-item {
  width: 550px;
  height: 550px;
  margin: 30px;
}
.Sidebar {
  margin-left: 100px;
}
.container {
  margin-left: 100px;
}

@media only screen and (max-width: 1790px) {
  .portfolio-item {
    width: 475px;
    height: 475px;
    margin: 25px;
  }
  .container {
    margin-left: auto;
  }
  .Sidebar {
    margin-left: 55px;
  }
}

@media only screen and (max-width: 1240px) {
  .portfolio-item {
    width: 375px;
    height: 375px;
    margin: 30px;
  }
  .portfolio-description .about_pic img {
    width: 95%;
  }
  .preface span {
    line-height: 1.5;
  }

  .video iframe {
    height: 300px;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) /*@media only screen and (max-width: 2000px) */ {
  :root {
    --body-margin: 20px;
    --sidebar-width: 0px;
    --header-width: 55px;

    --remainder-width: 20px;
    --item-width: calc(
      (
          100vw - var(--sidebar-width) - 2 * var(--body-margin) - 3 *
            var(--remainder-width)
        ) / 2
    );
  }
  .about_content > div {
    width: 100%;
    margin-bottom: 30px;
  }
  .title_below h2 {
    font-size: 16px;
  }
  body {
    margin: 0px;
  }

  .header {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05),
      0 6px 20px 0 rgba(0, 0, 0, 0.05);
    z-index: 90;
    opacity: 1;
    background-color: white;
    line-height: 1.5;
    height: 55px;
    height: var(--header-width);
    width: 100vw;
    /*transition: all 0.5s ease-in-out;*/
  }

  /*.container {
    /*margin: var(--body-margin);
    margin-top: calc(var(--header-width) + var(--remainder-width));
  }*/

  .portfolio-item {
    /*position: relative;
    margin-bottom: var(--remainder-width);*/
    width: 300px;
    height: 300px;
    margin: 15px;
  }

  .main-portfolio {
    margin-left: 0;
    width: 100%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    /*transition:all 2s;*/
    margin-top: 80px;
  }

  .Sidebar .Author {
    visibility: hidden;
    display: none;
  }

  .Sidebar {
    visibility: hidden;
    display: none;
  }

  .show {
    /*visibility: hidden;	*/
    /*display: none;	*/
    /*transition: all 1s ease-in-out;*/
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 1;
    visibility: visible;
    z-index: 99;
    margin-left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
  }

  .blurin {
    /*animation: bluring 2s ;		*/
    -webkit-filter: blur(8px);
            filter: blur(8px);
    /*transition: all 1s;*/
  }

  .list ul a {
    font-size: 24px;
    color: #ffffff;
  }

  .preface {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
  }

  .role,
  .time,
  .client,
  .built {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .award {
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0%;
    margin-bottom: -20px;
  }

  .video iframe {
    height: 300px;
    width: 100%;
  }

  .portfolio-description .pic img {
    width: 100%;
  }
  .desc {
    margin: 0;
    letter-spacing: 1px;
  }

  .subtitle {
    margin-bottom: 0px;
  }

  .subdesc {
    margin: 0;
    letter-spacing: 1px;
  }

  .portfolio-description .desc span {
    letter-spacing: 0.05em;
  }

  .nda {
    margin-left: 0;
    margin-right: 0;
  }

  .about {
    margin-right: 0%;
  }
  .about_content {
    margin: 0px;
  }
}

@media only screen and (max-width: 480px) {
  :root {
    --body-margin: 20px;
    --sidebar-width: 0px;
    --header-width: 55px;

    --remainder-width: 20px;
    --item-width: calc(
      (
        100vw - var(--sidebar-width) - 2 * var(--body-margin) - 2 *
          var(--remainder-width)
      )
    );
  }

  /*.container {
    /*margin: var(--body-margin);
    margin-top: var(--header-width);
    padding: var(--remainder-width);
  }
  */
  .container {
    flex-direction: column;
    justify-content: space-around;
  }
  .portfolio-item {
    /*position: relative;
    margin-bottom: var(--remainder-width);
    width: 100%;*/
    width: 315px;
    height: 315px;
  }

  div[class*="post"] {
    left: 0px;
    right: 0px;
    top: 0px;
  }

  .preface {
    margin: 0;
    margin-bottom: 40px;
  }

  .portfolio-description .title {
    margin-top: 80px;
  }

  .portfolio-description .about_pic img {
    width: 100%;
  }

  .role,
  .time,
  .client .built {
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .nda {
    margin-left: 0;
    margin-right: 0;
  }

  .award {
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0%;
    margin-bottom: -20px;
  }

  .portfolio-description .pic img {
    width: 100%;
  }

  .video iframe {
    height: 300px;
    width: 100%;
  }

  .desc {
    margin: 0;
    letter-spacing: 1px;
  }

  .portfolio-description .desc span {
    letter-spacing: 0.05em;
  }

  .about {
    margin-right: 0%;
  }

  body {
    padding: 55px;
    padding: var(--body-margin);
  }
}

.visible {
  visibility: visible;
}

.non-vivsible {
  visibility: hidden;
}

.message-me {
  position: absolute;
  width: 100%;
  height: 100%;
}

.message-me span {
  color: #ffffff;
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
}

.message-me input {
  color: black;
  font-family: "Open Sans", HanziPenSC, myHanziPenSC, sans-serif;
}

.message-me-email span {
  color: #4a4a4a;
}

.message-me-ques span {
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  line-height: 0.5em;
}

.message-me-select span {
  color: #4a4a4a;
  font-family: "Open Sans", HanziPenSC, myHanziPenSC, sans-serif;
}

.message-me-ques {
  background-color: #52acff;
  background-image: linear-gradient(145deg, #52acff 10%, #ffe32c 100%);
  /*background-color: #0077ff;*/
  position: relative;
  width: 100%;
  height: 40%;
  padding: 40px;
  font-size: 42px;
  border-radius: 20px 20px 0px 0px;
}

.message-me-ans {
  background-color: #f8f8f8;
  position: relative;
  width: 100%;
  height: 60%;
  padding: 40px;
  font-size: 14px;
  border-radius: 0px 0px 20px 20px;
}

.message-me-ans input {
  border: 0 solid red;
  border-bottom: 1px solid #000;
  background-color: #f8f8f8;
  line-height: 18px;
  font-size: 18px;
  width: 50%;
}

.message-me-ans input:focus {
  outline: none;
  width: 50%;
}

.message-me-ans a {
  color: #000000;
  margin-left: 10px;
  visibility: hidden;
  display: inline-block;
}

.message-me-select span {
  margin-top: 15px;
  display: block;
}

.message-me-select span:hover {
  cursor: pointer;
}

.message-me-email a {
  visibility: hidden;
  display: inline-block;
}

.message-me-email input {
  margin-left: 6px;
}

.message-me-final {
  display: none;
}

@media only screen and (max-width: 480px) {
  .message-me-ques {
    padding: 25px;
    font-size: 32px;
  }

  .message-me-ans {
    padding: 25px;
    height: 50%;
  }

  .message-me-ans a {
    font-size: 24px;
  }
  .message-me-ans input {
    width: 90%;
  }

  .message-me-ans input:focus {
    width: 90%;
  }

  .visible {
    font-size: 20px;
  }
}


@import url("https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap");

:root {
  /*--body-margin: 20px;*/
  --sidebar-width: 250px;

  /*--item-width: calc((100vw - var(--sidebar-width))/2 - 12vw);*/
  /*--remainder-width: calc((100vw - var(--sidebar-width) - 2*var(--item-width) - 2*var(--body-margin))/3);*/

  --remainder-width: 38px;
  --item-width: calc(
    (
        100vw - var(--sidebar-width) - 2 * var(--body-margin) - 3 *
          var(--remainder-width)
      ) / 2
  );
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

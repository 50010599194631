.Sidebar {
  position: fixed;
  left: 0;
  top: calc(var(--body-margin));
  width: 190px;
  margin-left: 55px;
  height: 80%;
  /*overflow: scroll; */
}

.Author {
  margin-bottom: 50px;
}

.Author p {
  font-size: 24px;
  text-align: left;
  font-family: AlTarikh;
  padding-left: 15px;
  color: #000000;
}

.Author img {
  width: 125px;
}

.list ul {
  list-style-type: none;
  text-align: left;
  padding-left: 15px;
  padding-top: 15px;
}

.list ul li {
  padding-bottom: 15px;
}

.list ul a {
  font-family: "ABeeZee", sans-serif;
  color: #000000;
}

.unfocused a,
.focused a {
  text-decoration: none;
}

.list div,
.sublist div {
  display: inline-block;
  :w ;
}
/********************************
sidebar sub-list
it becomes bold when hovering
*********************************/
.sublist {
  font-size: 12px;
  color: #ffffff;
}

.sublist .focused a {
  font-weight: bold;

  text-decoration: none;
  font-size: 14px;
}

.sublist .unfocused a {
  font-weight: normal;
  text-decoration: none;
  font-size: 14px;
}

/********************************
sidebar list
css sliding underline -- from left to right 
*********************************/

.list > div > a {
  display: inline-block;
}

.list > div > a:after {
  content: "";
  display: block;
  height: 1px;
  width: 0;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}

.list > .focused a:after {
  width: 100%;
  /*background: black;*/
  background: #ffffff;
}

@media only screen and (min-width: 768px) {
  .sublist > div > a:hover {
    font-weight: bold;
  }

  .list > div > a:hover:after {
    width: 100%;
    background: black;
  }
  .sublist .focused a {
    font-size: 12px;
  }

  .sublist .unfocused a {
    font-size: 12px;
  }
}

:root {
  --body-margin: 55px;
  --sidebar-width: 245px;
  /*--item-width: calc((100vw - var(--sidebar-width))/2 - 12vw);*/
  /*--remainder-width: calc((100vw - var(--sidebar-width) - 2*var(--item-width) - 2*var(--body-margin))/3);*/

  --remainder-width: 60px;
  --item-width: calc(
    (
        100vw - var(--sidebar-width) - 2 * var(--body-margin) - 3 *
          var(--remainder-width)
      ) / 2
  );

  --main-height: calc(100vh - 2 * var(--body-margin));
  --main-width: calc(100vw - 2 * var(--body-margin));

  --header-width: 55px;
}

body {
  /*margin: var(--body-margin);*/
  /*margin-top: calc(2*var(--body-margin));*/
  padding: var(--body-margin);
  font-size: 14px;
  line-height: 1.333333;
  letter-spacing: 0.05em;
}

* {
  box-sizing: border-box;
  visibility: visible;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.1;
}

span .thick {
  font-weight: bold;
}

.myPortfolio {
  position: relative;
}

div {
  display: block;
}

.ham {
  display: inline-block;
  width: 23px;
  height: 2px;
  position: relative;
  top: 2px;
  vertical-align: bottom;
  background: #b1004a;
  white-space: nowrap;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.menu-bar {
  min-width: 44px;
  height: 44px;
  text-align: center;
  display: inline-block;
  z-index: 101;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  -webkit-animation: blinkBackground 4s infinite;
  animation: blinkBackground 4s infinite;
  -webkit-box-shadow: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.menu-wrapper {
  display: table;
  table-layout: auto;
  width: 100%;
}

.header {
  display: none;
  padding: 13px;
}

.logo {
  display: table-cell;
  vertical-align: middle;
  width: 1%;
  font-size: 15px;
  line-height: 1;
  position: fixed;
  /*top:3.5%;*/
  left: 5%;
}

.logo img {
  width: 70px;
}

.menu {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  position: relative;
  line-height: 1.5;
  position: fixed;
  right: 5%;
  top: 1%;
  transition: all ease 0.3s;
}

.ham {
  background-color: #000;
}

.ham:after,
.ham:before {
  position: absolute;
  content: "";
  display: inline-block;
  width: 23px;
  height: 2px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  outline: 1px solid transparent;
  left: 0;
  background: #00b19e;
  background-color: #000;
}

.ham:after {
  top: 8px;
}

.ham:before {
  top: -8px;
}

a {
  background-color: transparent;
}

.exit {
  position: absolute;
  right: 5%;
  top: 1%;
  z-index: 100;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.exit span {
  color: #ffffff;
  font-size: 22px;
}
.exit .ham {
  background-color: transparent !important;
}

.exit .ham:before {
  transform: translateY(8px) rotateZ(-45deg);
  background-color: #fff;
}

.exit .ham:after {
  transform: translateY(-8px) rotateZ(45deg);
  background-color: #fff;
}

.main-portfolio {
  position: relative;
  width: calc(100% - var(--sidebar-width));
  margin-left: var(--sidebar-width);
  height: var(--main-height);
}

.main-portfolio-fullscreen {
  position: relative;
  width: var(--main-width);
  height: var(--main-height);
}

.headline {
  margin-top: -10px;
}

.sub-headline {
  margin-bottom: 35px;
}

.headline_2 {
  font-size: 30px;
  font-weight: 600;
  font-family: "ABeeZee", HanziPenSC-W3, myHanziPenSC, sans-serif;
  color: #0077ff;
  margin-top: 30px;
}

.headline_3 {
  font-size: 18px;
  font-family: "ABeeZee", HanziPenSC-W3, myHanziPenSC, sans-serif;
  margin-bottom: 5px;
  color: black;
  font-weight: 600;
}

.headline_4 {
  font-size: 14px;
  font-family: "Open Sans", HanziPenSC-W3, myHanziPenSC, sans-serif;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.pic {
  margin-top: 20px;
  margin-bottom: 35px;
}

.video iframe {
  height: 540px;
  width: 90%;
}

.preface {
  margin-top: 50px;
  margin-bottom: 40px;
  margin-left: 5%;
  margin-right: 18%;
  font-weight: 500;
}

.role,
.time,
.client,
.built {
  margin-left: 5%;
  margin-right: 10%;
  margin-bottom: 10px;
}

.award {
  margin-top: 40px;
  margin-left: 5%;
  margin-right: 10%;
  margin-bottom: -30px;
}

.nda {
  margin-top: 120px;
  margin-left: 5%;
  margin-right: 18%;
}

.role span,
.time span,
.client span,
.built {
  font-family: "Open Sans", HanziPenSC-W3, myHanziPenSC, sans-serif;
  color: #4a4a4a;
  font-size: 14px;
}

.award a {
  margin-top: 5px;
  font-size: 14px;
  font-family: "ABeeZee", HanziPenSC-W3, myHanziPenSC, sans-serif;
  color: #0077ff;
  font-weight: bold;
}

a:hover {
  color: #ffcb45;
}

.nda span {
  font-size: 18px;
  font-family: "Open Sans", HanziPenSC-W3, myHanziPenSC, sans-serif;
  color: lightgray;
  font-style: italic;
}

.about {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 0%;
}

.portfolio-description .title {
  margin-top: 120px;
  margin-bottom: 20px;
}

.portfolio-description {
  margin-top: 40px;
  margin-bottom: 0px;
}

.subtitle {
  margin-top: 30px;
  margin-bottom: -10px;
}

.desc {
  margin-top: 25px;
  /*margin-bottom: 25px;*/
  margin-right: 8%;
}

.external-link a {
  font-family: "ABeeZee", sans-serif, AndaleMono, myAndaleMono;
}
.external-link-text {
  /* background: linear-gradient(
    90deg,
    rgba(123, 212, 145, 1) 0%,
    rgba(232, 124, 82, 1) 100%
  );
  color: transparent;
  background-clip: text; */
  color: #0077ff;
}

.external-link {
  margin-top: 15px;
  margin-bottom: 15px;
  -webkit-text-stroke-width: 1px;
  font-size: 16px;
}

.bottom_margin {
  padding-bottom: 40px;
}

.subdesc {
  margin-top: 10px;
  /*margin-bottom: 25px;*/
  margin-right: 15%;
}

.portfolio-description .headline span {
  font-family: "ABeeZee", sans-serif;
  font-size: 36px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #000000;
  /*-webkit-text-stroke: 0.8px #4a4a4a;*/
  font-weight: 600;
  line-height: 1.25;
}

.portfolio-description .headline div {
  font-family: "ABeeZee", sans-serif;
  font-size: 36px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #000000;
  /*-webkit-text-stroke: 0.8px #4a4a4a;*/
  font-weight: 600;
  line-height: 1.5;
}

.portfolio-description .headline .headline_2 {
  margin-top: 0px;
  line-height: 1.4;
}

.portfolio-description .headline .headline_3 {
  font-size: 18px;
  color: black;
  letter-spacing: 0.05em;
  line-height: 1.5;
  margin-top: 10px;
}

.portfolio-description .sub-headline span {
  font-family: "ABeeZee", sans-serif;
  font-size: 18px;
  text-align: left;
  color: #4a4a4a;
}

.portfolio-description .pic img {
  width: 90%;
}
.portfolio-description .about_pic img {
  width: 80%;
  margin-bottom: 10px;
}
.portfolio-description {
  font-family: "ABeeZee", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.05em;
  line-height: 30px;
  text-align: justify;
  text-align: left;
  color: black;
}
.preface span {
  font-family: "ABeeZee", sans-serif;
  font-size: 22px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-align: justify;
  text-align: left;
  color: #4a4a44;
}

.about span {
  font-family: "Open Sans", HanziPenSC-W3, myHanziPenSC, sans-serif;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.08em;
  text-align: justify;
  text-align: left;
  color: #4a4a4a;
}

@font-face {
  font-family: myAndaleMono;
  src: url("./font/AndaleMono.ttf");
}

@font-face {
  font-family: myHanziPenSC;
  src: url("./font/HanziPenSC.ttf");
}

.portfolio-description .title span {
  /*font-family: HanziPenSC-W3;*/
  font-family: "Open Sans", HanziPenSC-W3, myHanziPenSC, sans-serif;
  font-size: 22px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #000000;
  font-weight: 600;
}

.portfolio-description .desc span {
  font-family: "Open Sans", AndaleMono, myAndaleMono, sans-serif;
  font-size: 14px;
  line-height: 1.79;
  letter-spacing: 0.08em;
  line-height: 30px;
  text-align: justify;
  text-align: left;
  color: #4a4a4a;
}

.portfolio-description {
  /*font-family: AndaleMono;*/
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  font-size: 12px;
  line-height: 1.79;
  letter-spacing: 0.08em;
  text-align: justify;
  text-align: left;
  color: #000000;
}

.subtitle span {
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  font-size: 16px;
  font-style: italic;
  line-height: 1.79;
  letter-spacing: 0.08em;
  text-align: justify;
  text-align: left;
  color: #000000;
}

.portfolio-description .subdesc span {
  /*font-family: AndaleMono;*/
  font-family: "Open Sans", AndaleMono, myAndaleMono, sans-serif;
  font-size: 12px;
  line-height: 1.79;
  letter-spacing: 0.08em;
  line-height: 30px;
  text-align: justify;
  text-align: left;
  color: #4a4a4a;
}

.previous-next {
  margin-top: 100px;
}

.previous-next span {
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  color: #000;
  font-size: 20px;
}

.previous-next div a {
  display: inline-block;
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  font-size: 18px;
  color: #000;
  margin-bottom: 30px;
}

.previous-next .previous-p {
  float: left;
}

.previous-next .next-p {
  float: right;
}

.wrapper {
  position: relative;
}

.container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /*justify-content: space-around;*/
}

.portfolio-item {
  /*position: absolute;
  width: var(--item-width);
  height: var(--item-width);*/
  width: 200px;
  height: 200px;
  margin: 25px;
  transition: all 1s ease-in-out;
  border-radius: 15px;
}

.about_content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 8%;
}

.about_content > div {
  width: 50%;
  margin-bottom: 20px;
}

/*.post0 {
  left: var(--remainder-width);
  top: 0;
}

.post1 {
  left: var(--remainder-width);
  top: 0;
}

.post2 {
  right: var(--remainder-width);
  top: 0;
}

.post3 {
  left: var(--remainder-width);
  top: calc(var(--item-width) + var(--remainder-width));
  top: calc(
    (
        100vw - var(--sidebar-width) - 2 * var(--body-margin) - 3 *
          var(--remainder-width)
      ) / 2 + var(--remainder-width)
  );
}

.post4 {
  right: var(--remainder-width);
  top: calc(var(--item-width) + var(--remainder-width));
  top: calc(
    (
        100vw - var(--sidebar-width) - 2 * var(--body-margin) - 3 *
          var(--remainder-width)
      ) / 2 + var(--remainder-width)
  );
}

.post5 {
  left: var(--remainder-width);
  top: calc(2 * var(--item-width) + 2 * var(--remainder-width));
  top: calc(
    (
        100vw - var(--sidebar-width) - 2 * var(--body-margin) - 3 *
          var(--remainder-width)
      ) + 2 * var(--remainder-width)
  );
}

.post6 {
  right: var(--remainder-width);
  top: calc(2 * var(--item-width) + 2 * var(--remainder-width));
  top: calc(
    (
        100vw - var(--sidebar-width) - 2 * var(--body-margin) - 3 *
          var(--remainder-width)
      ) + 2 * var(--remainder-width)
  );
}
*/

.item1 {
  background: url(./img/mainpage_3_0.png) no-repeat;
  background-size: 100%;
}

.item2 {
  background: url(./img/mainpage_2.jpg) no-repeat;
  background-size: 100%;
}

.item3 {
  background: url(./img/mainpage_1_0.png) no-repeat;
  background-size: 100%;
}

.item4 {
  background: url(./img/mainpage_4_0.png) no-repeat;
  background-size: 100%;
}

.item5 {
  background: url(./img/mainpage_5.jpg) no-repeat;
  background-size: 100%;
}

.item6 {
  background: url(./img/mainpage_6.png) no-repeat;
  background-size: 100%;
}

.item7 {
  background: url(./img/mainpage_7_0.png) no-repeat;
  background-size: 100%;
}

.item8 {
  background: url(./img/mainpage_8_1.png) no-repeat;
  background-size: 100%;
}

.item9 {
  background: url(./img/mainpage_9_0.png) no-repeat;
  background-size: 100%;
}

.item10 {
  background: url(./img/mainpage_10.png) no-repeat;
  background-size: 100%;
}

.item11 {
  background: url(./img/mainpage_11.png) no-repeat;
  background-size: 100%;
}

.item12 {
  background: url(./img/mainpage_12.png) no-repeat;
  background-size: 100%;
}
/*.portfolio-item:hover {*/
/*opacity: 0.98;*/
/*background-color: #eb6460;*/
/*}*/

@media (pointer: fine) {
  .hover-state {
    width: 100%;
    height: 100%;
    padding: 10%;
    position: relative;
    border-radius: 15px;
    box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
    transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  }
  .hover-state:hover {
    box-shadow: 2px 4px 12px rgb(0 0 0 / 35%);
  }
}

.info {
  position: absolute;
  left: 10%;
  right: 10%;
  top: 0;
}

.title h2 {
  position: relative;
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  font-size: 18px;
  letter-spacing: 0.08em;
  text-align: left;
  color: black;
  margin-bottom: 18px;
  /*-webkit-transform: translate(0,100px);
	transform: translate(0,15px);
	-webkit-transition: all .15s 75ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all .15s 75ms cubic-bezier(0.25, 0.46, 0.45, 0.94);*/
  letter-spacing: 0.05em;
  visibility: hidden;
  line-height: 1.5em;
}

.title_below h2 {
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  font-size: 20px;
  letter-spacing: 0.05em;
  text-align: left;
  color: black;
  opacity: 0.75;
  margin-left: 5%;
  margin-bottom: 15px;
  margin-right: 5%;
  line-height: 1.5em;
}
.title_below h2:hover {
  opacity: 1;
}

.subtitle h3 {
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  text-align: left;
  color: #ffffff;
  /*-webkit-transform: translate(0,100px);
	transform: translate(0,15px);
	-webkit-transition: all .15s 75ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all .15s 75ms cubic-bezier(0.25, 0.46, 0.45, 0.94);*/
  letter-spacing: 0.05em;
  visibility: hidden;
  font-weight: 600;
  margin-top: -15px;
}

.subtitle_below h3 {
  font-family: "ABeeZee", AndaleMono, myAndaleMono, sans-serif;
  letter-spacing: 0.05em;
  font-size: 12px;
  color: #9b9b9b;
  text-align: left;
  margin-left: 5%;
  margin-right: 5%;
  line-height: 1.5em;
  margin-bottom: 50px;
}

.hover-state:hover .info {
  position: absolute;
  left: 10%;
  top: 60%;
}

.hover-state:hover .info .title h2 {
  color: #ffffff;
}

.hover-state:hover .info .subtitle h3 {
  color: #ffe5e5;
  display: block;
}
.hover-state:hover .title h2 {
  -webkit-transform: translate(0, -100px);
  transform: translate(0, -15px);
  -webkit-transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  letter-spacing: 0.05em;
  /*visibility: visible;*/
}
.hover-state:hover .subtitle h3 {
  -webkit-transform: translate(0, -100px);
  transform: translate(0, -15px);
  -webkit-transition: all 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  letter-spacing: 0.05em;
  /*visibility: visible;*/
}

.portfolio-item {
  width: 550px;
  height: 550px;
  margin: 30px;
}
.Sidebar {
  margin-left: 100px;
}
.container {
  margin-left: 100px;
}

@media only screen and (max-width: 1790px) {
  .portfolio-item {
    width: 475px;
    height: 475px;
    margin: 25px;
  }
  .container {
    margin-left: auto;
  }
  .Sidebar {
    margin-left: 55px;
  }
}

@media only screen and (max-width: 1240px) {
  .portfolio-item {
    width: 375px;
    height: 375px;
    margin: 30px;
  }
  .portfolio-description .about_pic img {
    width: 95%;
  }
  .preface span {
    line-height: 1.5;
  }

  .video iframe {
    height: 300px;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) /*@media only screen and (max-width: 2000px) */ {
  :root {
    --body-margin: 20px;
    --sidebar-width: 0px;
    --header-width: 55px;

    --remainder-width: 20px;
    --item-width: calc(
      (
          100vw - var(--sidebar-width) - 2 * var(--body-margin) - 3 *
            var(--remainder-width)
        ) / 2
    );
  }
  .about_content > div {
    width: 100%;
    margin-bottom: 30px;
  }
  .title_below h2 {
    font-size: 16px;
  }
  body {
    margin: 0px;
  }

  .header {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05),
      0 6px 20px 0 rgba(0, 0, 0, 0.05);
    z-index: 90;
    opacity: 1;
    background-color: white;
    line-height: 1.5;
    height: var(--header-width);
    width: 100vw;
    /*transition: all 0.5s ease-in-out;*/
  }

  /*.container {
    /*margin: var(--body-margin);
    margin-top: calc(var(--header-width) + var(--remainder-width));
  }*/

  .portfolio-item {
    /*position: relative;
    margin-bottom: var(--remainder-width);*/
    width: 300px;
    height: 300px;
    margin: 15px;
  }

  .main-portfolio {
    margin-left: 0;
    width: 100%;
    filter: blur(0px);
    /*transition:all 2s;*/
    margin-top: 80px;
  }

  .Sidebar .Author {
    visibility: hidden;
    display: none;
  }

  .Sidebar {
    visibility: hidden;
    display: none;
  }

  .show {
    /*visibility: hidden;	*/
    /*display: none;	*/
    /*transition: all 1s ease-in-out;*/
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 1;
    visibility: visible;
    z-index: 99;
    margin-left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
  }

  .blurin {
    /*animation: bluring 2s ;		*/
    filter: blur(8px);
    /*transition: all 1s;*/
  }

  .list ul a {
    font-size: 24px;
    color: #ffffff;
  }

  .preface {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
  }

  .role,
  .time,
  .client,
  .built {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .award {
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0%;
    margin-bottom: -20px;
  }

  .video iframe {
    height: 300px;
    width: 100%;
  }

  .portfolio-description .pic img {
    width: 100%;
  }
  .desc {
    margin: 0;
    letter-spacing: 1px;
  }

  .subtitle {
    margin-bottom: 0px;
  }

  .subdesc {
    margin: 0;
    letter-spacing: 1px;
  }

  .portfolio-description .desc span {
    letter-spacing: 0.05em;
  }

  .nda {
    margin-left: 0;
    margin-right: 0;
  }

  .about {
    margin-right: 0%;
  }
  .about_content {
    margin: 0px;
  }
}

@media only screen and (max-width: 480px) {
  :root {
    --body-margin: 20px;
    --sidebar-width: 0px;
    --header-width: 55px;

    --remainder-width: 20px;
    --item-width: calc(
      (
        100vw - var(--sidebar-width) - 2 * var(--body-margin) - 2 *
          var(--remainder-width)
      )
    );
  }

  /*.container {
    /*margin: var(--body-margin);
    margin-top: var(--header-width);
    padding: var(--remainder-width);
  }
  */
  .container {
    flex-direction: column;
    justify-content: space-around;
  }
  .portfolio-item {
    /*position: relative;
    margin-bottom: var(--remainder-width);
    width: 100%;*/
    width: 315px;
    height: 315px;
  }

  div[class*="post"] {
    left: 0px;
    right: 0px;
    top: 0px;
  }

  .preface {
    margin: 0;
    margin-bottom: 40px;
  }

  .portfolio-description .title {
    margin-top: 80px;
  }

  .portfolio-description .about_pic img {
    width: 100%;
  }

  .role,
  .time,
  .client .built {
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .nda {
    margin-left: 0;
    margin-right: 0;
  }

  .award {
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0%;
    margin-bottom: -20px;
  }

  .portfolio-description .pic img {
    width: 100%;
  }

  .video iframe {
    height: 300px;
    width: 100%;
  }

  .desc {
    margin: 0;
    letter-spacing: 1px;
  }

  .portfolio-description .desc span {
    letter-spacing: 0.05em;
  }

  .about {
    margin-right: 0%;
  }

  body {
    padding: var(--body-margin);
  }
}
